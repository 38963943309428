@charset "utf-8";


/* #container
--------------------------- */
#container {
	top: 0;
	left: 0;
	width: 100%;
	position: absolute;
	z-index: 1;
}

/* .stageBase
--------------------------- */
.stageBase {
	width: 100%;
	position: relative;
	overflow: hidden;
}
.stageBase .fieldWrap {
	padding: 80px 0 0 0;
	height:100%;
}
@media screen and (max-width: 768px)  {
	.stageBase .fieldWrap {
		padding: 60px 0 0 0;
	}
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
	.stageBase .fieldWrap {
		padding: 160px 0 0 0;
	}
}

/* .stageSlide
--------------------------- */
.stageSlide {
	position: relative;
	overflow: hidden;
	visibility: hidden;
}
.stageSlide .slideWrap {
	top: 0;
	left: 0;
	position: absolute;
	overflow: hidden;
}
.stageSlide .slideWrap:before,
.stageSlide .slideWrap:after {
	content: " ";
	display: table;
}
.stageSlide .slideWrap:after {clear: both;}
.stageSlide .slideWrap {*zoom: 1;}

.stageSlide .slidePanel {
	float: left;
	overflow: hidden;
}

.stageSlide .sdPrev,
.stageSlide .sdNext {
	margin-top: -25px;
	top: 50%;
	width: 50px;
	height: 50px;
	display: block;
	position: absolute;
	z-index: 99;
}
.stageSlide .sdPrev {
	left: 80px;
	background: transparent url(../img/slide_prev.png) no-repeat left top;
}
.stageSlide .sdNext {
	right: 80px;
	background: transparent url(../img/slide_next.png) no-repeat left top;
}

.stageSlide .slideNav {
	bottom: 70px;
	left: 0;
	width: 100%;
	height: 15px;
	text-align: center;
	position: absolute;
	z-index: 98;
}
.stageSlide .slideNav a {
	margin: 0 5px;
	width: 15px;
	height: 15px;
	background: transparent url(../img/nav.png) no-repeat center center;
	display: inline-block;
	overflow: hidden;
}
.stageSlide .slideNav a.pnActive {
	background: transparent url(../img/nav_acv.png) no-repeat center center;
}

/* #pageNav
--------------------------- */
#pageNav {
	top: 0;
	right: 25px;
	width: 15px;
	text-align: center;
	position: fixed;
	z-index: 2;
}
#pageNav ul {
	width: 20px;
	display: block;
}
#pageNav ul li {
	margin-bottom: 10px;
	width: 20px;
	height: 20px;
	display: block;
	overflow: hidden;
}
#pageNav ul li a {
	width: 20px;
	height: 20px;
	background: transparent url(../img/nav.png) no-repeat center center;
	display: block;
}
#pageNav ul li.activeStage a {
	background: transparent url(../img/nav_acv.png) no-repeat center center;
}

@media screen and (max-width: 768px)  {
	#pageNav  {
		right: 10px;
	}
	#pageNav ul li {
		width: 10px;
		height: 10px;
	}
	#pageNav ul li a {
		width: 10px;
		height: 10px;
		background-size: 10px 10px;
	}
	#pageNav ul li.activeStage a {
		background-size: 10px 10px;
	}
}

/* #pageDown
--------------------------- */
#pageDown {
	bottom: 10px;
	left: 0;
	width: 100%;
	height: 59px;
	text-align: center;
	position: fixed;
	overflow: hidden;
	z-index: 3;
}
#pageDown a {
	margin: 0 auto;
	width: 66px;
	height: 59px;
	background: transparent url(../img/next_arw.svg) no-repeat center center;
	display: block;
}

@media screen and (max-width: 768px)  {
	#pageDown a {
		display: none;
	}
}
